import React from "react";
import styled from "styled-components";
import { Flex, Box } from "rebass/styled-components";
import { StaticQuery, graphql } from "gatsby";

import Header from "../Header";
import Footer from "../Footer";
import Doormat from "../Doormat";
import PrintHide from "../PrintHide";
import { colors } from "../../utils/theme";

const Container = styled(Box)`
  max-width: 1440px;
`;

const PageFlex = styled(Flex)`
  background-color: ${colors.brokenWhite};

  @media print {
    display: block;
    page-break-after: always;
  }
`;

const DoormatFlex = styled(Flex)`
  background-color: ${colors.lightBlue};
`;

const FooterFlex = styled(Flex)`
  background-color: ${colors.darkBlue};
`;

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        bio {
          name
          nationality
          languages
          email
          phone
          title
          headline
          description
          earlier
          url
          education {
            year
            title
          }
          awards {
            year
            title
          }
          recommendations {
            author
            quote
          }
        }
        social {
          github
          codewars
          linkedin
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___sortdate], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/skills.md/" } }
    ) {
      edges {
        node {
          html
        }
      }
    }
  }
`;

const Component = ({ children }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const siteMetadata = data.site.siteMetadata;
        const skills = data.allMarkdownRemark.edges[0].node.html;

        return (
          <div>
            <PageFlex>
              <Container px={20} py={40} mx="auto">
                <Header
                  bio={siteMetadata.bio}
                  social={siteMetadata.social}
                  skills={skills}
                />
                {children}
              </Container>
            </PageFlex>
            <DoormatFlex>
              <Container px={20} py={[40, 40, 80]} mx="auto">
                <Doormat bio={siteMetadata.bio} />
              </Container>
            </DoormatFlex>
            <PrintHide>
              <FooterFlex>
                <Container px={20} py={[40, 40, 100]} mx="auto">
                  <Footer bio={siteMetadata.bio} />
                </Container>
              </FooterFlex>
            </PrintHide>
          </div>
        );
      }}
    />
  );
};

export default Component;
