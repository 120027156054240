import React from "react";
import styled from "styled-components";

const PrintHide = styled.div`
  @media print {
    display: none;
  }
`;

const Component = ({ children }) => {
  return <PrintHide>{children}</PrintHide>;
};

export default Component;
