import React from "react";
import styled from "styled-components";

import GithubIcon from "../Icons/github";
import CodewarsIcon from "../Icons/codewars";
import LinkedinIcon from "../Icons/linkedin";
import { colors } from "../../utils/theme";

const SocialIcons = styled.ul`
  margin: 0;
  padding-left: 0;
`;

const SocialIcon = styled.li`
  display: inline-block;
  margin-right: 10px;
`;

const SocialLink = styled.a`
  display: inline-block;
  width: 30px;
  height: 30px;

  :hover #square {
    fill: ${colors.lightBlue} !important;
  }
`;

class SocialLinks extends React.PureComponent {
  onClick = (title) => {
    window.plausible(`Clicked ${title}`);
  };

  render() {
    return (
      <SocialIcons>
        {Object.entries(this.props.links).map(([key, url]) => (
          <SocialIcon key={key}>
            <SocialLink
              href={url}
              target="_blank"
              className={`social-icon--${key}`}
              onClick={() => this.onClick(key)}
            >
              {key === "github" && <GithubIcon />}
              {key === "codewars" && <CodewarsIcon />}
              {key === "linkedin" && <LinkedinIcon />}
            </SocialLink>
          </SocialIcon>
        ))}
      </SocialIcons>
    );
  }
}

export default SocialLinks;
