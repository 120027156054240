import React from "react";
import styled from "styled-components";

const Wrapper = styled.footer`
  color: #fff;
  text-align: center;
`;

const Footer = ({ bio }) => (
  <Wrapper>
    You can reach me by mail at
    <br />
    {bio.email}
  </Wrapper>
);

export default Footer;
