import React from "react";

const Component = () => (
  <svg viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon_linkedin">
        <path
          d="M28.5020313,0 C29.3293366,0 30,0.66865487 30,1.49796872 L30,28.5020313 C30,29.3293366 29.3313452,30 28.5020313,30 L1.49796872,30 C0.67066344,30 0,29.3313452 0,28.5020313 L0,1.49796872 C0,0.67066344 0.66865487,0 1.49796872,0 L28.5020313,0 Z"
          id="square"
          fill="#106D95"
        />
        <path
          d="M11.3617831,21.9060053 L11.3617831,11.4376034 L8.12626204,11.4376034 L8.12626204,21.9060053 L11.3617831,21.9060053 L11.3617831,21.9060053 Z M11.3617831,7.72701528 C11.3425114,6.7902015 10.7181583,6.07692307 9.70388051,6.07692307 C8.68933958,6.07692307 8.0264425,6.7902015 8.0264425,7.72701528 C8.0264425,8.64348197 8.67006732,9.37739007 9.6650734,9.37739007 L9.68434509,9.37739007 C10.7181583,9.37739007 11.3617831,8.64348197 11.3617831,7.72701528 L11.3617831,7.72701528 Z M16.6403551,21.9060056 L16.6403551,15.9416162 C16.6403551,15.6224113 16.663426,15.3032065 16.7572913,15.0756546 C17.0139194,14.437561 17.5979694,13.7770282 18.5786552,13.7770282 C19.8633756,13.7770282 20.6690842,14.5061425 20.6690842,15.9416161 L20.6690842,21.9060055 L23.9264421,21.9060056 L23.9264421,15.7820138 C23.9264421,12.5014731 22.17524,10.9749788 19.8393563,10.9749788 C17.9241271,10.9749788 17.083449,12.0457371 16.6166515,12.7745355 L16.6400388,11.3744305 L13.3883152,11.3744305 C13.4350897,12.3766071 13.3883152,21.9060055 13.3883152,21.9060055 L16.6403551,21.9060056 L16.6403551,21.9060056 Z"
          fill="#FFFFFF"
        />
      </g>
    </g>
  </svg>
);

export default Component;
