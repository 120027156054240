import React from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "rebass/styled-components";
import styled from "styled-components";
import slugify from "slugify";

import { breakpoints, colors } from "../../utils/theme";
import IconRole from "../../components/Icons/role";
import IconTech from "../../components/Icons/tech";
import IconTime from "../../components/Icons/time";
import IconLink from "../../components/Icons/link";
import Anchor from "../../components/Anchor";
import Video from "../../components/Video";
import PrintNoBreak from "../../components/PrintNoBreak";

const ProjectWrapper = styled.article`
  @media screen {
    &.printonly {
      display: none;
    }
  }
`;

const ProjectFlex = styled(Flex)`
  @media print {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const ProjectLine = styled(Box)`
  border-top: 2px solid ${colors.extreLightBlue};

  @media print {
    display: none;
  }
`;

const ProjectDescription = styled(Box)`
  @media screen {
    a {
      color: ${colors.lightBlue};
    }
    a:hover {
      color: ${colors.darkBlue};
    }
  }

  @media print {
    padding-left: 0;

    a {
      color: ${colors.darkBlue};
      text-decoration: none;
    }
  }
`;

const ProjectVideo = styled(Box)`
  min-width: 30%;

  @media (min-width: ${breakpoints[1]}) {
    max-width: 370px;
  }
  @media print {
    display: none;
  }
`;

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  svg {
    transform: translateY(5px);
    width: 20px;
    margin-right: 14px;
    margin-left: -35px;
  }

  li {
    margin-left: 35px;
  }
`;

const stripDomain = (string) =>
  string.match(/https?:\/\/(?:www\.)?([a-z0-9\-.]*)(?:\/|$)/)[1];

class Project extends React.PureComponent {
  onClick = (title) => {
    window.plausible(`Clicked ${title}`);
  };

  render() {
    const {
      html,
      frontmatter: { title, role, url, date, tags, video, poster, printonly },
    } = this.props.project;

    const id = slugify(title).toLowerCase();

    return (
      <ProjectWrapper className={printonly ? "printonly" : ""} id={id}>
        <PrintNoBreak>
          <ProjectFlex flexWrap={["wrap", "nowrap", "nowrap"]} my={60}>
            <ProjectLine flex="0 0 auto" order={10} width={[40, 40, 60]} />
            <ProjectDescription
              flex="1 1 auto"
              pl={[50, 40, 40]}
              pr={[0, 60, 60]}
              order={20}
            >
              <h2>{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: html }} />
              <Ul>
                <li>
                  <IconRole />
                  {role}
                </li>
                <li>
                  <IconTime />
                  {date}
                </li>
                <li>
                  <IconTech />
                  {tags}
                </li>
                {url && (
                  <li>
                    <IconLink />
                    <Anchor
                      href={url}
                      target="_blank"
                      onClick={() => this.onClick(title)}
                    >
                      {stripDomain(url)}
                    </Anchor>
                  </li>
                )}
              </Ul>
            </ProjectDescription>
            <ProjectVideo flex="1 1 100%" order={[1, 30, 30]} mb={[20, 20, 0]}>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                onClick={() => this.onClick(title)}
              >
                <Video video={video} poster={poster} browser disableOnMobile />
              </a>
            </ProjectVideo>
          </ProjectFlex>
        </PrintNoBreak>
      </ProjectWrapper>
    );
  }
}

Project.propTypes = {
  project: PropTypes.object,
};

export default Project;
