import React from "react";

const Component = () => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="1.414"
  >
    <rect id="Artboard2" width="20" height="20" fill="none" />
    <g clipPath="url(#_clip1)">
      <path
        d="M10,20C4.477,20 0,15.523 0,10C0,4.477 4.477,0 10,0C15.523,0 20,4.477 20,10C20,15.523 15.523,20 10,20ZM10,17.143C13.945,17.143 17.143,13.945 17.143,10C17.143,6.055 13.945,2.857 10,2.857C6.055,2.857 2.857,6.055 2.857,10C2.857,13.945 6.055,17.143 10,17.143ZM10.001,11.429L7.142,11.429C6.353,11.429 5.714,10.794 5.714,10C5.714,9.211 6.348,8.571 7.142,8.571L8.571,8.571L8.571,5.243C8.571,4.451 9.206,3.81 10,3.81C10.789,3.81 11.429,4.446 11.429,5.243L11.429,9.995L11.429,9.998L11.429,10C11.429,10.789 10.795,11.429 10.001,11.429Z"
        fill="#009adc"
      />
    </g>
  </svg>
);

export default Component;
