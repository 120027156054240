import React from "react";

const Component = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none">
      <path
        d="M8 15L8 13.7C5.1 12.8 3 10.2 3 7 3 3.1 6.1 0 10 0 13.9 0 17 3.1 17 7 17 10.2 14.9 12.8 12 13.7L12 15C15 15.1 17.6 17.2 18.4 20L5 20 1 20C1.9 17.1 4.5 15 7.7 15 7.8 15 7.9 15 8 15ZM7.5 8C8.3 8 9 7.3 9 6.5 9 5.7 8.3 5 7.5 5 6.7 5 6 5.7 6 6.5 6 7.3 6.7 8 7.5 8ZM12.5 8C13.3 8 14 7.3 14 6.5 14 5.7 13.3 5 12.5 5 11.7 5 11 5.7 11 6.5 11 7.3 11.7 8 12.5 8Z"
        fill="#009ADC"
      />
    </g>
  </svg>
);

export default Component;
