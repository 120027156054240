import React from "react";
import styled from "styled-components";

const PrintShow = styled.div`
  @media screen {
    display: none;
  }
`;

const Component = ({ children }) => {
  return <PrintShow>{children}</PrintShow>;
};

export default Component;
