import React from "react";
import styled from "styled-components";
import { colors } from "../../utils/theme";

const Anchor = styled.a`
  color: ${colors.lightBlue};

  &:hover {
    color: ${colors.darkBlue};
  }
`;

const Component = ({ children, ...rest }) => {
  return <Anchor {...rest}>{children}</Anchor>;
};

export default Component;
