import React from "react";
import { Flex, Box } from "rebass/styled-components";
import styled from "styled-components";

import PrintHide from "../PrintHide";
import PrintShow from "../PrintShow";
import SocialLinks from "../SocialLinks";
import Bio from "../../components/Bio";
import Anchor from "../../components/Anchor";
import {colors} from "../../utils/theme";

const AnchorDiv = styled.div`
  margin-bottom: 15px;
`;

const BioWrapper = styled(Box)`
  width: 100%;
`;

const Photo = styled.img`
  float: right;
  max-width: 25%;
`;

const Button = styled.button`
  border: 0;
  background-color: ${colors.darkBlue};
  color: #fff;
  padding: 8px 18px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.lightBlue};
  }
`;

const Header = ({ bio, social, skills }) => (
  <header>
    <PrintHide>
      <Flex
        justifyContent="space-between"
        flexWrap={["wrap", "wrap", "nowrap"]}
      >
        <Box flex="0 0 auto" order={10}>
          <SocialLinks links={social} />
          <AnchorDiv>
            <Anchor href={`mailto:${bio.email}`}>{bio.email}</Anchor>
          </AnchorDiv>
        </Box>
        <BioWrapper
          flex="0 1 auto"
          order={[40, 40, 20]}
          px={[0, 0, 40]}
          mb={[60, 60, 60]}
          mt={[50, 50, 0]}
        >
          <Bio name={bio.name} headline={bio.headline} />
        </BioWrapper>
        <Box flex="0 0 auto" order={30}>
          <Anchor href={`mailto:${bio.email}`}><Button>Contact me</Button></Anchor>
        </Box>
      </Flex>
    </PrintHide>
    <PrintShow>
      <Photo src="xK71hUwXmct3hRzF.jpg" alt={bio.name} />
      <h4>
        Curriculum Vitae
        <br />
        {bio.name}
      </h4>
      <p>{bio.title}</p>
      <table>
        <tbody>
          <tr>
            <td>Nationality</td>
            <td>{bio.nationality}</td>
          </tr>
          <tr>
            <td>Languages</td>
            <td>{bio.languages}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{bio.phone}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{bio.email}</td>
          </tr>
          <tr>
            <td>Website</td>
            <td>{bio.url}</td>
          </tr>
          <tr>
            <td>LinkedIn</td>
            <td>{social.linkedin}</td>
          </tr>
          <tr>
            <td>Github</td>
            <td>{social.github}</td>
          </tr>
        </tbody>
      </table>
      <Bio
        name={bio.name}
        description={bio.description}
        skills={skills}
        printVersion
      />
    </PrintShow>
  </header>
);

export default Header;
